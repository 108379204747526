/* globals document window */

export const HTML = document.getElementsByTagName('html')[0];
export const BODY = document.body || document.getElementsByTagName('body')[0];
export const WINDOW = window;
export const DOCUMENT = document;

export const lockScrolling = function lockScrolling(
  scrollTop = WINDOW.pageYOffset || BODY.scrollTop || 0
) {
  BODY.style.top = `-${scrollTop}px`;
  BODY.classList.add('no-scroll');
};

export const unlockScrolling = function unlockScrolling() {
  const lastScrollTop = Math.abs(parseInt(BODY.style.top, 10));
  BODY.style.top = null;
  BODY.classList.remove('no-scroll');
  WINDOW.scrollTo(0, lastScrollTop);
};

export const getDimensions = function getDimensions() {
  return {
    height: WINDOW.innerHeight,
    width: WINDOW.innerWidth,
  };
};

export const STORAGE = {
  get: () => {
    return JSON.parse(WINDOW.localStorage.getItem('sleep-primary-care-resources-geolocation'));
  },
  set: (obj) => {
    WINDOW.localStorage.setItem('sleep-primary-care-resources-geolocation', JSON.stringify(obj));
    return obj;
  }
};

export default {
  lockScrolling,
  unlockScrolling,
  getDimensions,
  HTML,
  BODY,
  WINDOW,
  DOCUMENT,
  STORAGE,
};
