/*
===============================================================================
  Webpack imports for css, img and fonts
===============================================================================
*/
// Stylesheets
import '../scss/app.scss';

// Images
import '../img/hero-homepage-desktop.jpg';
import '../img/racgp-logo.png';

/*
===============================================================================
  Application imports
===============================================================================
*/

import { WINDOW, DOCUMENT, BODY } from './helpers/browser';
import './events';

async function bootstrap() {
  if (Array.from(BODY.querySelectorAll('.js-tabs'))) {
    const { default: Tabs } = await import(
      /* webpackChunkName: "modules-Tabs" */ './modules/Tabs'
    );
    Array.from(BODY.querySelectorAll('.js-tabs')).forEach(el => Tabs(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-header'))) {
    const { default: Header } = await import(
      /* webpackChunkName: "modules-Header" */ './modules/Header'
    );
    Array.from(BODY.querySelectorAll('.js-header')).forEach(el => Header(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-questionnaire'))) {
    const { default: Questionnaire } = await import(
      /* webpackChunkName: "modules-Questionnaire" */ './modules/Questionnaire'
    );
    Array.from(BODY.querySelectorAll('.js-questionnaire')).forEach(el =>
      Questionnaire(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-abbr'))) {
    const Abbreviations = await import(
      /* webpackChunkName: "modules-Abbreviations" */ './modules/Abbreviations'
    );
    Array.from(BODY.querySelectorAll('.js-abbr')).forEach(
      el => new Abbreviations.default(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-accordion'))) {
    const Accordion = await import(
      /* webpackChunkName: "modules-Accordion" */ './modules/Accordion'
    );
    Array.from(BODY.querySelectorAll('.js-accordion')).forEach(
      el => new Accordion.default(el)
    );
  }
}

((window, document) => {
  if (document.readyState === 'complete' || document.readyState !== 'loading') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
})(WINDOW, DOCUMENT);

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
